import { Routes, RouterModule } from "@angular/router";

export const FullLayout_ROUTES: Routes = [
  {
    path: "authentication",
    loadChildren: () =>
      import("../../authentication/authentication.module").then(
        (m) => m.AuthenticationModule
      ),
  },
  {
    path: "vendor-offers",
    loadChildren: () =>
      import("../../offers/offers.module").then((m) => m.OffersModule),
  },
  {
    path: "vendor-bills",
    loadChildren: () =>
      import("../../bills/bills.module").then((m) => m.BillsModule),
  },
  {
    path: "vendor-orders",
    loadChildren: () =>
      import("../../orders/orders.module").then((m) => m.OrdersModule),
  },
];
