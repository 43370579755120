import { Component, OnDestroy, OnInit } from "@angular/core";
import { ThemeConstantService } from "../../services/theme-constant.service";
import messages from "../../../../assets/data/global/header/messages.json";
import notification from "../../../../assets/data/global/header/notification.json";
import authorMenu from "../../../../assets/data/global/header/author-menu.json";
import settings from "../../../../assets/data/global/header/settings.json";
import { NotificationsService } from "src/app/core/api/services/notifications.service";
import { TranslateService } from "@ngx-translate/core";
import { SharedService } from "../../services/shared.service";
import { SseService } from "src/app/core/api/services/sse.service";
import { Subscription } from "rxjs";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit, OnDestroy {
  user = JSON.parse(localStorage.getItem("user"));

  searchVisible: boolean = false;
  quickViewVisible: boolean = false;
  isFolded: boolean;
  isExpand: boolean;
  appMessages = messages.appMessages;
  appNotification = notification.appNotification;
  appAuthorMenu = authorMenu.appAuthorMenu;
  appSettings = settings.appSettings;
  notifications: any;
  language: string;
  notifLength = 0;

  constructor(
    private themeService: ThemeConstantService,
    private notificationService: NotificationsService,
    private sharedService: SharedService,
    private sseService: SseService,
    private toastr: ToastrService
  ) {
    this.sharedService._languageValueBS.subscribe((res: string) => {
      if (res) {
        this.language = res;
      }
    });

    if (localStorage.getItem("language")) {
      this.language = localStorage.getItem("language");
    }
  }

  changeLanguage(language: string) {
    this.sharedService.updateComp1Val(language);
  }

  signOut(): void {
    localStorage.clear();
    window.location.reload();
    localStorage.clear();
  }
  private sseSubscription: Subscription;
  ngOnInit(): void {
    

    this.themeService.isMenuFoldedChanges.subscribe(
      (isFolded) => (this.isFolded = isFolded)
    );
    this.themeService.isExpandChanges.subscribe(
      (isExpand) => (this.isExpand = isExpand)
    );

    this.notificationService.getAll().subscribe((res: any) => {
      this.notifications = res;

      if (this.notifLength < res.length) {
        this.toastr.success(res[0].contentAr, res[0].titleAr);
      }

      this.notifLength = res.length;
    });
    
   
  }

  ngOnDestroy(): void {
    if (this.sseSubscription) {
      this.sseSubscription.unsubscribe();
    }
  }

  toggleFold() {
    this.isFolded = !this.isFolded;
    this.themeService.toggleFold(this.isFolded);
  }

  toggleExpand() {
    this.isFolded = false;
    this.isExpand = !this.isExpand;
    this.themeService.toggleExpand(this.isExpand);
    this.themeService.toggleFold(this.isFolded);
  }

  searchToggle(): void {
    this.searchVisible = !this.searchVisible;
  }

  quickViewToggle(): void {
    this.quickViewVisible = !this.quickViewVisible;
  }
}
