import { Injectable, NgZone } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { endpoints } from "../constants/endpoints";

@Injectable({
  providedIn: "root",
})
export class SseService {
  constructor(private ngZone: NgZone) {}

  getServerSentEvent(): Observable<any> {
    return new Observable<any>((observer) => {
      const userId = JSON.parse(localStorage.getItem("user"))?.id;

      const eventSource = new EventSource(
        `${environment.base_url}/api/notifications/sse/${userId}`
      );

      eventSource.onmessage = (event) => {
        console.log(event);
        this.ngZone.run(() => {
          observer.next(event);
        });
      };

      eventSource.onerror = (error) => {
        console.log(event);
        this.ngZone.run(() => {
          observer.error(error);
        });
      };
    });
  }
}
