import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class NotificationsService {
  constructor(
    private httpClient: HttpClient // private authService: AuthenticationService
  ) {}

  getAll = () =>
    this.httpClient.get("https://api.procurement.barsha.co/api/notifications");
}
