<div
  class="border-b header dark:border-none bg-white dark:bg-[#323540] min-h-[72px] fixed top-0 w-full z-[1000] flex"
>
  <div
    class="dark:border-transparent logo logo-dark transition duration-200 ease-in-out items-center h-[71px]"
  >
    <a class="block text-center text-md font-semibold px-4">
      <img
        id="logo-img"
        class="ps-[30px] w-16 h-10 object-cover"
        src="https://play-lh.googleusercontent.com/Jjivu28njvwJPsgentF5xC6W9bN-U2viL2acjeDJauhGSvJeWjdzsxFNEOsABwwe9-_T=w240-h480-rw"
        alt="Logo"
      />
      <img
        id="logo-fold-img"
        class="p-[24px]  logo-fold"
        src="https://play-lh.googleusercontent.com/Jjivu28njvwJPsgentF5xC6W9bN-U2viL2acjeDJauhGSvJeWjdzsxFNEOsABwwe9-_T=w240-h480-rw"
        alt="Logo"
      />

      مؤسسة الجيل التالي التجارية
    </a>
  </div>
  <div
    class="flex flex-1 nav-wrap md:ps-[20px] md:pe-[30px] ps-[10px] pe-[15px] bg-white dark:bg-[#1b1d2a]"
  >
    <ul
      class="flex items-center mb-0 list-none nav-left ps-0 gap-x-[14px] gap-y-[9px]"
    >
      <!-- <li>
        <a class="inline-flex items-center bg-normalBG hover:bg-primary/10 hover:text-primary dark:bg-[#282b37] dark:text-white/60 min-h-[34px] max-sm:w-[34px] max-sm:justify-center px-4 max-sm:px-0 rounded-2xl gap-[8px] dark:hover:bg-white/60 group dark:hover:text-dark transition duration-200 capitalize" (click)="quickViewToggle()">
          <i nz-icon nzType="edit" theme="outline"></i>
          <span class="text-sm font-medium text-body group-hover:text-primary dark:text-white/60 dark:group-hover:text-current max-sm:hidden">
            customize...
          </span>
        </a>
        <nz-drawer [nzWidth]="280" nzTitle="Theme Config" [nzClosable]="false" [nzVisible]="quickViewVisible"
          nzPlacement="right" (nzOnClose)="quickViewToggle()">
          <ng-container *nzDrawerContent>
            <app-quick-view></app-quick-view>
          </ng-container>
        </nz-drawer>
      </li> -->
    </ul>
    <!-- Top Menu -->

    <!-- desktop -->
    <div class="items-center hidden ms-auto xl:flex">
      <ng-container *ngTemplateOutlet="myTemplate"></ng-container>
    </div>
    <ng-template #myTemplate>
      <ul
        class="flex items-center justify-end flex-auto mb-0 list-none ps-0 nav-right sm:gap-x-[25px] max-sm:gap-x-[15px] gap-y-[15px]"
      >
        <li>
          <a
            class="flex items-center"
            nz-dropdown
            [nzDropdownMenu]="popLanguage"
          >
            <nz-badge>
              <img
                *ngIf="language == 'en-US'"
                class="w-[20px] h-[20px]"
                src="assets/images/others/flags/english.png"
                alt=""
              />
              <img
                *ngIf="language == 'ar-SA'"
                class="w-[20px] h-[20px]"
                src="https://cdn4.iconfinder.com/data/icons/world-flags-circular/1000/Flag_of_Palestine_-_Circle-512.png"
                alt=""
              />
            </nz-badge>
          </a>
          <nz-dropdown-menu #popLanguage="nzDropdownMenu">
            <ul class="block bg-white dark:bg-[#1b1d2a] rounded-6">
              <li
                class="bg-white dark:bg-white/10 hover:bg-primary/10 dark:hover:bg-primary/10"
              >
                <button
                  (click)="changeLanguage('ar-SA')"
                  class="flex items-center px-3 py-1.5 text-sm text-dark dark:text-white/60"
                >
                  <img
                    class="w-3.5 h-3.5 me-2"
                    src="https://cdn4.iconfinder.com/data/icons/world-flags-circular/1000/Flag_of_Palestine_-_Circle-512.png"
                    alt="english"
                  />
                  <span>العربية</span>
                </button>
              </li>
              <li
                class="bg-white dark:bg-white/10 hover:bg-primary/10 dark:hover:bg-primary/10"
              >
                <button
                  (click)="changeLanguage('en-US')"
                  class="flex items-center px-3 py-1.5 text-sm text-dark dark:text-white/60"
                >
                  <img
                    class="w-3.5 h-3.5 me-2"
                    src="assets/images/others/flags/english.png"
                    alt="english"
                  />
                  <span>English</span>
                </button>
              </li>
            </ul>
          </nz-dropdown-menu>
        </li>
      </ul>
    </ng-template>
    <!-- desktop -->
    <!-- Mobile -->
    <a
      class="items-center hidden max-xl:flex text-light text-[18px] dark:text-white/[.87] ms-auto"
      nz-dropdown
      nzTrigger="click"
      [nzDropdownMenu]="menu"
    >
      <span nz-icon nzType="more" nzTheme="outline"></span>
    </a>
    <nz-dropdown-menu #menu="nzDropdownMenu">
      <div
        class="bg-white dark:bg-[#1b1c29] px-[20px] py-[6px] rounded-6 hidden max-xl:block"
      >
        <ng-container *ngTemplateOutlet="myTemplate"></ng-container>
      </div>
    </nz-dropdown-menu>
    <!-- Mobile -->
  </div>
</div>
