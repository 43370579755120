import { SideNavInterface } from "../../interfaces/side-nav.type";

export const ROUTES_CUSTOMER_DASHBOARD: SideNavInterface[] = [
  {
    path: "/dashboard/demo-two",
    title: "sidenav.dashboard",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "dashboard",
    submenu: [],
  },
];

export const ROUTES_CUSTOMER_INVENTORY: SideNavInterface[] = [
  {
    path: "/v1/products",
    title: "sidenav.products",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "gold",
    submenu: [],
  },
  {
    path: "/v1/groups",
    title: "sidenav.groups",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "group",
    submenu: [],
  },
  {
    path: "/v1/workspace",
    title: "sidenav.workspace",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "apartment",
    submenu: [],
  },
  {
    path: "/v1/vendors",
    title: "sidenav.vendors",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "shop",
    submenu: [],
  },
];

export const ROUTES_CUSTOMER_ESOURCING: SideNavInterface[] = [
  {
    path: "/v1/requests",
    title: "sidenav.requests",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "file-text",
    submenu: [],
  },

  {
    path: "/v1/manage-sale-offers",
    title: "sidenav.sale_offers",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "file-text",
    submenu: [],
  },

  {
    path: "/v1/e-sourcing",
    title: "sidenav.esourcing",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "tag",
    submenu: [],
  },

  {
    path: "/v1/orders",
    title: "sidenav.orders",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "shopping-cart",
    submenu: [],
  },
  {
    path: "/v1/grn",
    title: "sidenav.grn",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "unordered-list",
    submenu: [],
  },
  {
    path: "/v1/bills",
    title: "sidenav.bills",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "file-done",
    submenu: [],
  },

  {
    path: "/v1/payments",
    title: "sidenav.payments",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "wallet",
    submenu: [],
  },
];

export const ROUTES_CUSTOMER_SETTING: SideNavInterface[] = [
  {
    path: "/v1/settings",
    title: "sidenav.settings",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "setting",
    submenu: [
      {
        path: "/v1/settings",
        title: "sidenav.employee",
        iconType: "nzIcon",
        iconTheme: "outline",
        icon: "tag",
        submenu: [],
      },
      {
        path: "/v1/taxes",
        title: "sidenav.tax",
        iconType: "nzIcon",
        iconTheme: "outline",
        icon: "tag",
        submenu: [],
      },
      {
        path: "/v1/credit-terms",
        title: "sidenav.credit_terms",
        iconType: "nzIcon",
        iconTheme: "outline",
        icon: "tag",
        submenu: [],
      },
      {
        path: "/v1/order-terms",
        title: "sidenav.order_terms",
        iconType: "nzIcon",
        iconTheme: "outline",
        icon: "tag",
        submenu: [],
      },
      {
        path: "/v1/currencies",
        title: "sidenav.currencies",
        iconType: "nzIcon",
        iconTheme: "outline",
        icon: "tag",
        submenu: [],
      },
      {
        path: "/v1/shipping-term",
        title: "sidenav.shipping_term",
        iconType: "nzIcon",
        iconTheme: "outline",
        icon: "tag",
        submenu: [],
      },

      {
        path: "/v1/Payment-terms",
        title: "sidenav.payment_terms",
        iconType: "nzIcon",
        iconTheme: "outline",
        icon: "tag",
        submenu: [],
      },
    ],
  },
  {
    path: "/v1/reports",
    title: "sidenav.reports",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "bar-chart",
    submenu: [],
  },
];

export const ROUTES_EMPLOYEE: SideNavInterface[] = [
  {
    path: "/v1/actions",
    title: "sidenav.action_board",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "file-text",
    submenu: [],
  },
];
export const ROUTES_CLIENT: SideNavInterface[] = [
  {
    path: "/v1/requests",
    title: "sidenav.requests",
    iconType: "nzIcon",
    iconTheme: "outline",
    icon: "file-text",
    submenu: [],
  },
];
