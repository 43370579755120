import { Component } from "@angular/core";
import { SharedService } from "../../services/shared.service";

@Component({
  selector: "app-public-header",
  templateUrl: "./public-header.component.html",
  styleUrls: ["./public-header.component.css"],
})
export class PublicHeaderComponent {
  language: string;
  constructor(private sharedService: SharedService) {
    this.sharedService._languageValueBS.subscribe((res: string) => {
      if (res) {
        this.language = res;
      }
    });

    if (localStorage.getItem("language")) {
      this.language = localStorage.getItem("language");
    }
  }

  changeLanguage(language: string) {
    this.sharedService.updateComp1Val(language);
  }
}
