import { Component } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { SharedService } from "./shared/services/shared.service";
import { NzI18nService, ar_EG, en_US } from "ng-zorro-antd/i18n";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent {
  direction: string;
  constructor(
    private translate: TranslateService,
    private sharedService: SharedService,
    private i18n: NzI18nService
  ) {
    this.sharedService._languageValueBS.subscribe((res: string) => {
      if (res) {
        this.sharedService.language = res;
        translate.setDefaultLang(res);
        translate.use(res);
        localStorage.setItem("language", res);
        if (res == "en-US") {
          localStorage.setItem('direction', 'ltr')
          this.i18n.setLocale(en_US);
          this.direction = "ltr";
        }
        if (res == "ar-SA") {
          localStorage.setItem('direction', 'rtl')
          this.i18n.setLocale(ar_EG);
          this.direction = "rtl";
        }
      }
    });

    if (localStorage.getItem("language")) {
      translate.setDefaultLang(localStorage.getItem("language"));
      translate.use(localStorage.getItem("language"));
      this.sharedService.language = localStorage.getItem("language");

      if (localStorage.getItem("language") == "en-US") {
        this.i18n.setLocale(en_US);
        this.direction = "ltr";
        localStorage.setItem('direction', 'ltr')
      }
      if (localStorage.getItem("language") == "ar-SA") {
        this.i18n.setLocale(ar_EG);
        this.direction = "rtl";
        localStorage.setItem('direction', 'rtl')
      }
    } else {
      localStorage.setItem("language", "ar-SA");
      translate.setDefaultLang(localStorage.getItem("language"));
      translate.use(localStorage.getItem("language"));
      this.sharedService.language = localStorage.getItem("language");

      if (localStorage.getItem("language") == "en-US") {
        this.i18n.setLocale(en_US);
        this.direction;
        localStorage.setItem('direction', 'ltr')
      }
      if (localStorage.getItem("language") == "ar-SA") {
        this.i18n.setLocale(ar_EG);
        this.direction = "rtl";
        localStorage.setItem('direction', 'rtl')
      }
    }
  }
}
